.header {
    width: 100%;
}

.box {
    height: 100px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid gray;
}

.logo {
    display: flex;
    align-items: center;
    gap: 10px;
}

.logo a {
    display: flex;
    align-items: center;
}

.logo_text {
    text-transform: uppercase;
    letter-spacing: 1rem;
    font-weight: bolder;
    font-size: 2rem;
    padding-left: 10px;
}

.menu {
    display: flex;
    align-items: center;
    font-size: 22px;
    line-height: 28px;
    gap: 30px;
}

.mobile_btn {
    display: none;
}

.activeTab {
    text-decoration: underline;
}

/*@media (max-width: 900px) and (min-width: 720px) {*/
/*    .logo_text {*/
/*        display: none;*/
/*    }*/
/*}*/

@media (max-width: 720px) {
    .mobile_btn {
        display: block;
        position: absolute;
        right: 20px;
        top: 37px;
        cursor: pointer;
        z-index: 10;
    }

    .menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: fixed;
        left: -100%;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100vh;
        background-color: #f8f8f8;
        z-index: 10;
        transition: left .2s;
    }

    .active {
        left: 0;
    }

    .menu a {
        font-size: 28px;
    }
}
